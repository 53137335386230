/* ************************
   04.10: Contact
   ********************* */

   .contact-form {
       button.btn {
           @extend .c2-bg;
           &:hover {
               @extend .black-bg;
           }
       }
   }

    @include customMw(1200px) {
        .contact-info {
            margin-left: 65px;
        }
    }
    
    .contact-info {
        .img {
            margin-bottom: 60px;
        }
        font-size: 18px;
    }

    .contact-info-list {
        @extend .list-unstyled;
        li {
            padding-left: 50px;
            position: relative;
            @extend .black;
            @include mobileSm {
                br {
                    display: none;
                }
            }
            &:after {
                width: 21px;
                height: 30px;
                position: absolute;
                left: 0;
                top: 4px;
                content: "";
                background-image: url(../img/icons/location.svg);
                background-repeat: no-repeat;
            }
            &:nth-child(2) {
                &:after {
                    background-image: url(../img/icons/phone.svg);
                }
            }
            &:nth-child(3) {
                &:after {
                    background-image: url(../img/icons/email.svg);
                }
            }
            &:not(:last-child) {
                margin-bottom: 20px;
            }

            a {
                display: block;
                @extend .black;
            }
        }
    }

    .faq-tab-title {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 30px;
        @include tab {
            text-align: center;
        }
    }

    .nav-tab {
        a {
            padding: 0;
            font-size: 18px;
            @extend .medium;
            @extend .poppins;
            @extend .black;
            letter-spacing: -1px;
            &:not(:last-child) {
                margin-bottom: 12px;
            }
            &.active,
            &:hover {
                @extend .c2;
            }
        }
        @include tab {
            margin-bottom: 40px;
            text-align: center;
        }
    }

    .accordion {
        > div {
            border: none;
            background-color: #EFF6FB;
            @extend .radius-5;
            overflow: hidden;
            &:not(:last-child) {
                margin-bottom: 10px;
            }

            &.active {
                h3 {
                    @extend .c2;
                    &:after {
                        height: 2px;
                        background-image: url(../img/icons/tab-minus.png);
                    }
                }
            }
        }

        h3 {
            background-color: #EFF6FB;
            border: none;
            padding: 21.5px 30px;
            font-size: 18px;
            @extend .poppins;
            @extend .black;
            @extend .medium;
            @extend .trans3;
            position: relative;
            width: 100%;
            text-align: left;
            cursor: pointer;
            user-select: none;
            line-height: 1.3;
            @include mobileSm {
                padding: 20px 15px;
                padding-right: 34px;
            }
            &:after {
                width: 14px;
                height: 14px;
                right: 30px;
                top: 50%;
                transform: translateY(-50%);
                background-image: url(../img/icons/tab-plus.png);
                content: "";
                position: absolute;
                @extend .trans3;
                @include mobileSm {
                    right: 20px;
                }
            }
        }

        .accordion-content {
            padding: 21px 30px;
            padding-top: 0;
            @include mobileSm {
                padding: 20px 15px;
                padding-right: 34px;
            }
        }
    }