/* ************************
   03.2: Banner
   ********************* */

   .banner {
      background: transparent radial-gradient(closest-side at 50% 50%, #0C5FE7 0%, #16149F 100%) 0% 0% no-repeat padding-box;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      overflow-x: hidden;

      display: flex;
      align-items: center;
      min-height: 972px;
      padding-top: 160px;
      padding-bottom: 60px;
      position: relative;
      z-index: 1;

      @include IE {
         padding-top: 300px;
      }

      .banner-shape {
         position: absolute;
         z-index: -1;
         left: 3.5%;
         top: 124px;
      }
   }

   // @include IE {
   //    .container {
   //       width: 1140px;
   //    }
   // }

   .banner-content {
      @extend .white;
      .sub-title {
         @extend .c2;
         text-transform: uppercase;
         margin-bottom: 15px;
         font-size: 16px;
      }
      h1 {
         line-height: 1.266;
         margin-bottom: 26px;
         letter-spacing: -1.5px;
         @extend .white;
         @include mobileMd {
            font-size: 40px;
         }
      }

      p {
         font-size: 18px;
         line-height: 1.666;
         margin-bottom: 38px;
         @include mobileMd {
            br {
               display: none;
            }
         }
      }
      @include tab {
         margin-bottom: 40px;
      }
   }

   .banner-img {
      position: relative;
      .shield-img,
      .man1,
      .man2,
      .man3,
      .man4 {
         position: absolute;
         left: 50px;
         top: 194px;
         transition: .1s;
      }
      .main-img {
         max-width: initial;
         position: relative;
      }
      .shield-img {
         left: 195px;
         top: 147px;
         animation: levitate 3s linear .5s infinite forwards;
      }
      .man2 {
         top: auto;
         left: 426px;
         bottom: 20px;
      }
      .man3 {
         top: auto;
         left: 215px;
         bottom: 22px;
      }
      .man4 {
         top: 100px;
         left: auto;
         right: 96px;
      }
   }

   .banner-btn-group {
      display: flex;
      align-items: center;

      .video-btn {
         margin-left: 30px;
      }
      @include mobileMd {
         flex-direction: column;
         align-items: flex-start;
         .video-btn {
            margin-top: 20px;
            margin-left: 0px;
         }
      }
   }