/* ************************
   04.8: Counter Statistics
   ********************* */
   .counter-statistics {
        background-color: transparent;
        background-image: radial-gradient(at center center, #7875F4 0%, #5551EF 100%);
        position: relative;
        .section-pattern-img {
            width: 100%;
            top: 0;
            left: 0;
        }
   }

   .contact-form-wrap {
       @include tab {
           margin-bottom: 60px;
       }
   }

   .single-counter {
       background-color: rgba(255, 255, 255, 0.05);
       padding: 60px 15px 55px;
       text-align: center;
       margin-bottom: 30px;

       .counter-icon {
           margin-bottom: 24px;
       }

       .counter-content {
           @extend .white;
           h2 {
               font-size: 48px;
               @extend .white;
               margin-bottom: 16px;
           }
       }
   }