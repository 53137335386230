/* ************************
   04.6: Testimonial
   ********************* */

   .single-testimonial-slide {
      text-align: center;
      @extend .white;
      .img {
         margin: 0 auto 25px;
         width: 90px;
         height: 90px;
         position: relative;
         &:after {
            width: 22px;
            height: 19px;
            position: absolute;
            content: "";
            right: -6px;
            top: 10px;
            background-image: url(../img/icons/testimonial-quote.png);
         }
         img {
            @extend .radius;
            width: auto;
         }
      }
      .content {
         > p {
            font-size: 18px;
            margin-bottom: 26px;
            @include mobileLg {
               br {
                  display: none;
               }
            }
         }

         .meta-info {
            p {
               font-size: 14px;
               margin-bottom: 5px;
            }
            h4 {
               font-size: 24px;
               @extend .white;
            }
         }
      }
   }