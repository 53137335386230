/* ************************
   03.6: Coming Soon
   ********************* */
   .coming-soon-content {
      .logo {
         margin-bottom: 100px;
      }
      h1 {
         font-size: 80px;
         margin-bottom: 70px;
         @extend .white;
      }
      > p{
         max-width: 680px;
         margin: 0 auto;
      }
      > p {
         font-size: 18px;
         @extend .white;
         margin-bottom: 40px;
      }
      .coming-soon-form {
         max-width: 550px;
         margin: 0 auto;
         .newsletter-form {
            button {
               padding: 9px 45px;
            }
         }
      }
   }
