/* ************************
   04.1: Thread Onlines
   ********************* */

   .pattern-wrap {
       position: relative;
       z-index: 1;
       .pattern {
           position: absolute;
           right: -80px;
           bottom: -50px;
           z-index: -1;
           @include tab {
               right: -20px;
               bottom: -20px;
           }
       }
   }

    .single-online-thread {
        @extend .white-bg;
        @extend .box-shadow;
        border-radius: 3px;
        padding: 70px 30px 65px;
        margin-bottom: 30px;
        text-align: center;

        .img {
            display: inline-block;
            margin-bottom: 35px;
            img {
                @extend .trans3;
            }
        }

        .content {
            h4 {
                margin-bottom: 24px;
                letter-spacing: -1.5px;
            }
        }

        &:hover {
            .feature-icon {
                img {
                    transform: rotate(5deg);
                }
            }
        }
    }