// Colors
$c1: #16149F;
$c2: #00E3F7;
$text-color: #7F828C;
$white: #ffffff;
$black: #354463;

// Fonts
$poppins: 'Poppins', sans-serif;
$rubik: 'Rubik', sans-serif;

// Font Weight
$thin: 100;
$extra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;