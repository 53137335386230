/* ************************
   02.4: Buttons
   ********************* */
.btn {
    font-size: 15px;
    text-transform: uppercase;
    @extend .white;
    @extend .medium;
    @extend .c2-bg;
    @extend .trans3;
    z-index: 1;
    border-radius: 50px;
    line-height: 1;
    padding: 14px 30px;
    border: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    &:focus {
        box-shadow: none;
    }

    svg {
        margin-right: 7px;
        path {
            @extend .trans3;
        }
    }

    &:hover {
        @extend .black-bg;
        @extend .white;
        svg path {
            fill: #354463;
        }
    }
    &.btn-shadow {
        box-shadow: 10px 10px 45px rgba(0, 227, 247, .3);
        &:hover {
            box-shadow: 10px 10px 45px rgba(22, 20, 159, .3);
        }
        &.btn-black {
            box-shadow: 10px 10px 45px rgba(22, 20, 159, .3);
            &:hover {
                box-shadow: 10px 10px 45px rgba(0, 227, 247, .3);
            }
        }
    }
    &.btn-black {
        @extend .black-bg;
        svg path {
            fill: #354463;
        }

        &:hover {
            @extend .c2-bg;
            svg path {
                fill: $c2;
            }
        }

    }
    &.btn-sm {
        font-size: 12px;
        padding: 9px 20px;
        svg {
            width: 18px;
            height: 18px;
        }
    }
}

.btn-link {
    @extend .text-color;
    @extend .trans5;
    @extend .semi-bold;
    text-transform: uppercase;
    font-size: 13px;
    display: inline-flex;
    align-items: center;

    svg {
        margin-right: 7px;
        circle {
            @extend .trans3;
        }
    }

    &:hover {
        text-decoration: none;
        @extend .c2;
        svg {
            circle {
                fill: $c2;
            }
        }
    }
}