/* ************************
   04.5: Team
   ********************* */

   .single-team {
        @extend .white-bg;
        margin-bottom: 30px;

        .img {
            margin-bottom: 30px;
            position: relative;
            img {
                width: 100%;
            }
        }
        .content {
            h4 {
                font-size: 24px;
                margin-bottom: 10px;
                letter-spacing: -1px;
            }
            > p {
                line-height: 1;
            }
        }
    }

    .team-socials {
        position: absolute;
        right: 15px;
        bottom: -18px;
        a {
            width: 35px;
            height: 35px;
            @extend .c2-bg;
            @extend .white;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            position: relative;
            transition: all .1s ease-in-out;
            &:not(:last-child) {
                @extend .c1-bg;
                bottom: 0;
                transform: translateY(0);
                z-index: 1;
                position: absolute;
                bottom: 0;
            }
        }

        &:hover {
            a {
                transition: all .3s ease-in-out;
                &:not(:last-child) {
                    transform: translateY(-104%);
                }
                &:nth-child(2) {
                    transform: translateY(-207%);
                }
                &:nth-child(3) {
                    transform: translateY(-310%);
                }
                &:nth-child(4) {
                    transform: translateY(-413%);
                }
                &:nth-child(5) {
                    transform: translateY(-516%);
                }
                &:nth-child(6) {
                    transform: translateY(-619%);
                }
                &:last-child {
                    transform: translateY(0);
                }
            }
        }
    }