/* ************************
   03.3: Blog
   ********************* */

   .single-blog {
      margin-bottom: 30px;
      display: inline-block;
      .blog-img {
         border-radius: 5px 5px 0 0;
         img {
            border-radius: 5px 5px 0 0;
            width: 100%;
         }
      }

      .blog-content {
         @extend .white-bg;
         @extend .box-shadow2;
         padding: 27px 30px;
         position: relative;
         border-radius: 0 0 5px 5px;
         @include mobileSm {
            padding: 40px 20px 32px;
         }

         h4 {
            margin-bottom: 16px;
            font-size: 24px;
            line-height: 1.416;
            letter-spacing: -1px;
            a {
               @extend .black;
            }
         }
      }

      .blog-meta {
         ul {
            margin-bottom: 8px;
            li {
               &:not(:last-child) {
                  margin-right: 20px;
               }
               a {
                  font-size: 14px;
                  display: flex;
                  align-items: center;

                  svg {
                     margin-right: 7px;
                     position: relative;
                     top: -1px;
                  }
               }
            }
         }
      }
   }

   @include tab {
      .blog-sidebar {
         margin-top: 60px;
      }
   }


   /* Blog Details */
   .post-details {
      font-size: 16px;
      p {
         line-height: 1.86;
      }
      figure {
         margin-top: 50px;
         margin-bottom: 50px;
      }
      figcaption {
         margin-top: 15px;
      }
      .entry-header {
         .entry-thumbnail {
            margin-bottom: 47px;
         }
         .entry-title {
            font-size: 36px;
            line-height: 1.28;
            margin-bottom: 15px;
            @extend .bold;
            letter-spacing: -.5px;
         }
         .entry-meta {
            margin-bottom: 20px;
            li {
                  &:not(:last-child) {
                     margin-right: 50px;
                  }
                  span {
                     font-weight: $semi-bold;
                     margin-right: 7px;
                     font-size: 16px;
                  }
                  a {
                     @extend .text-color;
                     font-size: 14px;
                     &:hover {
                        @extend .c2;
                     }
                  }
            }
         }
      }

      .entry-tags,
      .entry-categories {
         ul {
            li {
                  &:first-child {
                     @extend .poppins;
                     color: $black;
                     font-size: 16px;
                     font-weight: $bold;
                     margin-right: 5px;
                  }
                  a {
                     @extend .text-color;
                     font-size: 14px;
                     line-height: 1.57;
                     &:not(:last-child) {
                        &:after {
                              content: ',';
                        }
                     }
                     &:hover {
                        @extend .c1;
                     }
                  }
            }
         }
      }
   }

   /* Blog Details Content */
   .entry-content,
   .comment-content,
   .page--content {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
         line-height: 1.5;
         margin-bottom: 23px;
      }
      p {
         margin-bottom: 25px;
      }
      table {
         margin-bottom: 50px;
      }

      select {
         @extend .theme-input-style;
         margin-bottom: 50px;
      }
      
      > * {
         &:last-child {
            margin-bottom: 0;
         }
      }
      a {
         color: $black;
         font-weight: $bold;
         font-size: 14px;
         &:hover {
            text-decoration: underline;
         }
         i {
            margin-right: 5px;
         }
      }
   }

   /* Post Author Info */
   .post-author {
      padding: 40px 0;
      @include mobileMd {
         flex-direction: column;
      }
      .author-thumb {
         margin-right: 30px;
         @include mobileMd {
            margin-right: 0;
            margin-bottom: 30px;
         }
      }
      .author-info {
         h4 {
            font-size: 18px;
            margin-bottom: 10px;
            color: $black;
            font-weight: $semi-bold;
         }
         p {
            font-size: 16px;
            margin-bottom: 20px;
         }
      }
   }
   .post-title {
      color: $black;
   }
   /* Post Navigation */
   .post-navigation {
      .nav-link {
         flex-basis: 50%;
         max-width: 50%;
         padding: 0;
         .nav-title {
            display: block;
            font-size: 14px;
            @extend .bold;
            opacity: .5;
            margin-bottom: 17px;
            color: $black;
            @extend .trans3;
            i {
                  margin-right: 10px;
            }
            &.text-right {
                  i {
                  margin-left: 10px;
            }
            }
         }
         .posted-on {
            margin: 10px 0 2px;
            display: block;
            font-size: 14px;
            @extend .text-color;
         }
         &:hover {
            .nav-title {
                  opacity: 1;
            }
         }
      }
   }

   /* Related Post */
   .related-post {
      h2 {
         margin-bottom: 30px;
      }
      h4 {
         color: $black;
         margin-bottom: 30px;
      }
   }

   /* Post Comments */
   .comments-area {
      .comments-title {
         margin-bottom: 25px;
         color: $black;
      }
      ul {
         margin: 0;
         padding: 0;
         list-style: none;
      }
      .comment {
         .single-comment {
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 1px solid #E2E2E2;
            @include mobileSm {
                  flex-direction: column;
            }
            .comment-author-image {
                  min-width: 65px;
                  margin-right: 30px;
                  border-radius: 50%;
                  overflow: hidden;
                  @include mobileSm {
                     margin-right: 0;
                     margin-bottom: 30px;
                  }
            }
            &:not(.trackback):not(.pingback) {
                  .comment-content {
                     max-width: calc(100% - 95px);
                     width: 100%;
                     @include mobileSm {
                        max-width: 100%;
                     }
                  }
            }
            .comment-content {
                  .author_name {
                     margin-right: 10px;
                     margin-bottom: 0;
                     font-size: 16px;
                  }
                  .commented-on {
                     font-size: 12px;
                  }
                  p {
                     margin: 7px 0 5px;
                     font-size: 16px;
                     color: $text-color;
                     font-weight: $regular;
                  }
            }
         }
         ul.children {
            margin-left: 95px;
            @include mobileSm {
                  margin-left: 20px;
            }
         }
      }
   }

   /* Comments Form */
   .comment-respond {
      @include tab {
         margin-bottom: 60px;
      }
      .comment-reply-title {
         margin-bottom: 20px;
         color: $black;
      }
      .comment-form {
         .comment-notes {
            margin-bottom: 30px;
            font-size: 16px;
         }
         .theme-input-style {
            border-radius: 25px;
            border: 1px solid #EEEEEE;
            @extend .text-color;
            padding: 10px 20px;
            &:focus {
                  background-color: #F1F3FF;
            }
         }
         label {
            margin-bottom: 18px;
         }
         .custom-checkbox {
            margin-bottom: 5px;
            label {
               font-size: 16px;

               input[type=checkbox] {
                  margin-right: 8px;
               }
            }
         }

         .btn-wrap {
            .btn {
               @extend .c2-bg;
               &:hover {
                  @extend .black-bg;
               }
            }
         }
      }
   }