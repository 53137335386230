/*
	*******************
	Template name:  Govpn
	Version:        1.0
	Author:         ThemeLooks
	Author url:     http://themelooks.com

	NOTE:
	-----
	Please DO NOT EDIT THIS CSS, you may need to use "custom.css" file for writing your custom css.
	We may release future updates so it will overwrite this file. it's better and safer to use "custom.css".

    ******** CSS INDEX ********
    01. Base
        1.1 Reset 
        1.2 Margin/Padding
        1.3 color
    02. Components
        2.1 Common Classes
        2.2 Animations
        2.3 Preloader
        2.4 Button
        2.5 Section Title
        2.6 Page Title
        2.7 Widgets
        2.8 Back to Top
    03. Layout
        3.1 Header 
        3.2 Banner
        3.3 Blog
        3.4 Footer
        3.5 404
        3.6 Coming Soon
    04. Sections
        4.1 Thread Online
        4.2 Use GoVPN
        4.3 Call To Action
        4.4 Price
        4.5 Team
        4.6 Testimonial
        4.7 About
        4.8 Counter
        4.9 Service
        4.10 Contact

    ********************/
    
// IMPORT ALL DIRECTORY SASS FILE
@import '00-abstracts/00-abstracts-dir';
@import '01-base/00-base-dir';
@import '02-components/00-component-dir';
@import '03-layout/00-layout-dir';
@import '04-section/00-section-dir';