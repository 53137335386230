/* ************************
   04.9: Service
   ********************* */

    .single-service {
        @extend .box-shadow;
        @extend .white-bg;
        @extend .radius-5;
        padding: 60px 40px 54px;
        margin-bottom: 30px;
        position: relative;

        @include mobileLg {
            padding: 40px 20px 34px;
        }
        &:after {
            width: 0%;
            height: 1px;
            left: 0;
            bottom: 0;
            position: absolute;
            @extend .c2-bg;
            content: "";
        }

        .img {
            display: inline-block;
            margin-bottom: 30px;
            img {
                @extend .trans3;
            }
        }

        .content {
            h4 {
                margin-bottom: 30px;
                font-size: 24px;
                letter-spacing: -1.5px;
            }
            P {
                margin-bottom: 25px;
            }
        }

        &:hover,
        &.active {
            &:after {
                width: 100%;
                @extend .trans5;
            }
            .content {
                .btn-link {
                        text-decoration: none;
                        @extend .c2;
                        svg {
                            circle {
                                fill: $c2;
                            }
                        }
                }
            }
        }
    }