/* ************************
   02.2: Animations
   ********************* */
.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    
    100% {
        opacity: 1;
    }    
}
  
@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100%{
        opacity: 1;
    }
}
    
.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
  
    100% {
        opacity: 0;
    }
}    

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    
    100% {
        opacity: 0;
    } 
}
  
.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}    

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0,-40px,0);
        transform: translate3d(0,-40px,0);
    }
    
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}
    
@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0,40px,0);
        transform: translate3d(0,40px,0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }    
}
    
.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

@keyframes matrix {
    0% {
        transform: matrix(1, 0, 0, 1, 0, 0);
    }
    25% {
        transform: matrix(1, 0, 0, 1, 15, 20);
    }
    50% {
        transform: matrix(1, 0, 0, 1, -5, 25);
    }
    75% {
        transform: matrix(1, 0, 0, 1, -15, 15);
    }
    100% {
        transform: matrix(1, 0, 0, 1, 0, 0);
    }
}


@keyframes fadeInDown {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.fadeInDown {
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.fadeInUp {
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}



@-webkit-keyframes slideOutUp {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	to {
		visibility: hidden;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
}

@keyframes slideOutUp {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	to {
		visibility: hidden;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
}

.slideOutUp {
	-webkit-animation-name: slideOutUp;
	animation-name: slideOutUp;
}

@-webkit-keyframes slideInDown {
	from {
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInDown {
	from {
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}

  	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
  	}
}

.slideInDown {
	-webkit-animation-name: slideInDown;
	animation-name: slideInDown;
}

@-webkit-keyframes ripple {
	0% {
        transform: scale(1);
        opacity: 1;
	}
	100% {
        transform: scale(1.5);
        opacity: 0;
	}
}

@keyframes ripple {
	0% {
        transform: scale(1);
        opacity: 1;
	}
	100% {
        transform: scale(1.5);
        opacity: 0;
	}
}

@-webkit-keyframes levitate{
    from{
        transform: translate(0,0)
    }
    50%{
        transform: translate(0,10px)
    }
    to{
        transform: translate(0,0)
    }
}
@keyframes levitate{
    from{
        transform: translate(0,0)
    }
    50%{
        transform: translate(0,10px)
    }
    to{
        transform: translate(0,0)
    }
}

@keyframes topToBottom {
    0% {
        top: 0%;
    }
    100% {
        top: 100%;
    }
}
