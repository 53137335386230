/* ************************
   02.1: Common Classes
   ********************* */
/* Transitions */
.trans2 {
    @include transition(.2s, ease-in-out);
}

.trans3 {
    @include transition(.3s, ease-in-out);
}

.trans4 {
    @include transition(.4s, ease-in-out);
}

.trans5 {
    @include transition(.5s, ease-in-out);
}

.trans7 {
    @include transition(.5s, ease-in-out);
}

/* Font Family */
h1,
h2,
h3,
h4,
h5,
h6,
.poppins {
    font-family: $poppins;
}

body,
.rubik {
    font-family: $rubik;
}

/* Text White */
.text-white {
    *,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $white;
    }
} 

/* Border Radius */
.radius-5 {
    border-radius: 5px;
}

.radius-10 {
    border-radius: 10px;
}

.radius-50 {
    border-radius: 50px;
}

.radius {
    border-radius: 50%;
}

.regular {
    font-weight: $regular;
}

.medium {
    font-weight: $medium;
}

.semi-bold {
    font-weight: $semi-bold;
}

.bold {
    font-weight: $bold;
}

.extra-bold {
    font-weight: $extra-bold;
}

/* Box Shadow */
.box-shadow {
    box-shadow: 10px 20px 45px rgba(0, 0, 0, 0.07);
}

.box-shadow2 {
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
}

.no-shadow {
    box-shadow: none !important;
}

.bg-img {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    &.blog {
        background-position: center top;
        background-size: contain;
    }
}

/* List Style */
.list-unstyled {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.list-inline {
    @extend .list-unstyled;
    li {
        display: inline-block;
    }
}

// .list-check {
//     margin-bottom: 0;
//     padding: 0;
//     list-style: none;
//     li {
//         font-size: 18px;
//         line-height: 1.67;
//         padding-left: 35px;
//         position: relative;
//         &:not(:last-child) {
//             margin-bottom: 20px;
//         }
//         &:after {
//             position: absolute;
//             @include fa('\f00c');
//             line-height: inherit;
//             @extend .c2;
//             left: 5px;
//             top: -2px;
//         }

//         &:before {
//             position: absolute;
//             left: 0;
//             top: 5px;
//             content: '';
//             width: 18px;
//             height: 18px;
//             border-radius: 50%;
//             background-color: #FF988D;
//             z-index: -1;
//             opacity: .2;
//         }
//     }
// }

.list-dot {
    li {
        &:not(:last-child) {
            margin-bottom: 40px;
            &:after {
                position: absolute;
                content: "";
                background-image: url(../img/icons/line.svg);
                width: 1px;
                height: 85%;
                top: 35px;
                left: 11.5px;
            }
        }

        h5 {
            margin-bottom: 15px;
        }

        padding-left: 40px;
        position: relative;

        p {
            font-size: 16px;
        }

        a {
            @extend .c2;
        }
        
        &:before {
            position: absolute;
            left: 0;
            top: -5px;
            background-image: url(../img/icons/list-dot.svg);
            content: "";
            width: 24px;
            height: 24px;
        }
    }
}

.all-country-img {
    img {
        max-width: none;
        margin-left: -67px;

        @include medium {
            max-width: 120%;
        }
        @include tab {
            max-width: 100%;
        }
        @include mobileLg {
            margin-left: 0;
        }
    }
}

#countdown {
    @include tab {
       flex-wrap: wrap;
    }
    li {
        margin-bottom: 60px;
        width: 130px;
        min-width: 130px;
        height: 130px;
        border: 1px solid $white;
        @extend .radius;
        display: flex;
        align-items: center;
        justify-content: center;
        @extend .text-white;
        position: relative;
        &:before,
        &:after {
            width: 100%;
            height: 100%;
            @extend .radius;
            left: 0;
            top: 0;
            position: absolute;
            content: "";
            border: 1px solid rgba($white, .15);
            transform: scale(0.8);
        }
        &:after {
            transform: scale(1.2);
        }
        &.seperator {
            font-size: 36px;
            @extend .white;
            width: 100px;
            height: auto;
            border: none;
            &:before,
            &:after {
                display: none;
            }
            @include tab {
                display: none;
            }
        }
        .single-countdown {
            padding: 10px;
            text-align: center;
            @include mobileSm {
                padding: 50px;
            }

            h2 {
                font-size: 36px;
                @extend .semi-bold;
            }
            span {
                text-transform: uppercase;
                font-size: 14px;
                @extend .semi-bold;
            }
        }
    }
}

svg path {
    @extend .trans3;
}

// Social Links
.social-links {
    a {
        width: 40px;
        height: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(53, 68, 99, 0.35);
        @extend .white;
        @extend .radius;

        &:not(:last-child) {
            margin-right: 7px;
        }

        &:hover {
            @extend .white;
            @extend .c2-bg;
        }

    }
    &.style--two {
        a {
            width: auto;
            height: auto;
            background-color: transparent;
            border-radius: 0px;
            margin-left: 22px;
            font-size: 17px;
            &:not(:last-child) {
                margin-right: 0px;
            }
            &:hover {
                i {
                    color: $c2;
                    @extend .trans3;
                }
            }
        }
    }
    &.contact-social-links {
        a {
            background-color: #3C79D9;
            @extend .white;
            font-size: 16px;
            margin-top: 40px;
            &:not(:last-child) {
                margin-right: 20px;
            }
            &:nth-child(2) {
                background-color: #00CDF8;
            }
            &:nth-child(3) {
                background-color: #D18DFC;
            }
            &:hover {
                opacity: 0.9;
                i {
                    color: $white;
                }
            }
        }
    }
    &.style--three {
        margin-top: 95px;
        a {
            border: 1px solid $white;
            @extend .white;
            width: 45px;
            height: 45px;
            &:not(:last-child) {
                margin-right: 20px;
            }
            &:hover {
                @extend .c2;
                background-color: transparent;
            }
        }
    }
}

.inline-menu {
    @extend .list-inline;
    li {
        &:not(:first-child) {
            margin-left: 20px;
            @include mobileSm {
                margin-left: 16px;
            }
        }
    }
}

// Video Btn
.video-btn {
	font-size: 15px;
	text-transform: uppercase;
	line-height: 1;
	@extend .white;
	@extend .medium;
	display: inline-flex;
    align-items: center;
    i {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        @extend .white-bg;
        @extend .c2;
        @extend .trans3;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
    }
    span {
        position: relative;
        z-index: 1;
        margin-right: 20px;
        display: inline-block;
        &:after,
        &:before {
            width: 100%;
            height: 100%;
            border: 1px dotted $c2;
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            transform: scale(1);
            @extend .radius;
            opacity: 0;
            animation: ripple 2s ease-in 0s infinite forwards;
            z-index: -1;
        }
        &:before {
            animation-delay: .5s;
        }
    }

    &:hover {
        color: #fff;
        i {
            color: #5551EF;
        }
    }
}

/* Owl Dots */
.owl-carousel {
    .owl-dots {
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        display: flex;
        align-items: center;
    }
    button.owl-dot {
        margin: 0 7.5px;
        width: 7px;
        height: 7px;
        display: block;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: rgba(85, 81, 239, 0.2);
        &.active {
            background-color: rgba(85, 81, 239, 1);
            width: 9px;
            height: 9px;
            &:after {
                content: "";
                position: absolute;
                background-color: rgba(85, 81, 239, 0.2);
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                transform: scale(2);
            }
        }
    }
}

/* Owl Navs */
.owl-carousel {
    .owl-nav {
        @include mobileLg {
            display: none;
        }
        button {
            position: absolute;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);

            &.owl-next {
                left: auto;
                right: 0px;
            }

            svg path {
                @extend .trans3;
            }

            &:hover {
                svg path {
                    fill: $c2;
                }
            }
        }
    }
}

.logo-carousel {
    padding-bottom: 100px;
    &.style--two {
        padding-top: 100px;
    }
}

/* Theme Input Group */
.theme-input-group {
    position: relative;
    width: 100%;
    input {
        width: 100%;
        height: 50px;
        border: none;
        padding-left: 15px;
        padding-right: 50px;
        color: $white;
        background-color: #334E95;
    }
    button {
        position: absolute; 
        top: 4px;
        right: 4px;
        height: calc(100% - 8px);
        padding: 9px 28px;
        line-height: 1;
        font-weight: $semi-bold;
        color: $white;
        @extend .trans2;
        @extend .c2-bg;
        &:hover {
            background-color: #fb9389;
        }
    }
}

/* Form Control */
.form-control {
    border-radius: 7px;
    border: none;
    background-color: #EFF6FB;
    height: 60px;
    width: 100%;
    padding: 0 20px;
    font-size: 16px;
    @extend .trans3;
    margin-bottom: 30px;
    &:focus {
        box-shadow: none;
        background-color: #EFF6FB;
    }
}

textarea.form-control {
    height: 170px;
    padding: 20px 20px;
}

//Newsletter form
.newsletter-form {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    background-color: #EFF6FB;
    @extend .radius-50;
    @extend .ov-hidden;

    .form-control {
        margin-bottom: 0;
        padding-left: 30px;
        &:focus {
            background-color: #EFF6FB;
        }
    }

    button {
        @extend .c2-bg;
        @extend .radius-50;
        padding: 10px 50px;
    }

    &.style--two {
        height: 60px;
        padding-right: 6px;
        input {
            height: 48px;
        }
        button {
            padding: 9px 27px;
        }
    }
}

.newsletter {
    .newsletter-form {
        padding-right: 5px;
    }
}

//grid animation
.grid-animation {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    div {
       position: absolute;
       width: 1px;
       height: 100%;
       background-color: rgba(255, 255, 255, 0.1);
       left: 18%;
       top: 0;

       &:after,
       &:before {
          position: absolute;
          width: 7px;
          height: 7px;
          @extend .white-bg;
          @extend .radius;
          left: -3px;
          top: -7px;
          content: "";
          opacity: 1;
          animation: topToBottom 40s linear infinite forwards;
       }

       &:nth-child(1) {
          &:after {
             animation-delay: 10s;
          }
          &:before {
             animation-delay: 35s;
          }
       }

       &:nth-child(2) {
          left: 36%;
          &:after {
             animation-delay: 0s;
          }
          &:before {
             animation-delay: 25s;
          }
       }
       &:nth-child(3) {
          left: 54%;
          &:after {
             animation-delay: 15s;
          }
          &:before {
             animation-delay: 30s;
          }
       }
       &:nth-child(4) {
          left: 82%;
          &:after {
             animation-delay: 5s;
          }
          &:before {
             animation-delay: 20s;
          }
       }
    }
}

@include customMw(992px) {
    .no-gutters-lg {
        margin-right: 0;
        margin-left: 0;
      
        > .col,
        > [class*="col-"] {
          padding-right: 0;
          padding-left: 0;
        }
    }
}

/* Others */
.ov-hidden {
    overflow: hidden;
}
.ovx-hidden {
    overflow-x: hidden;
}

/* Pagination */
.pagination {
    margin-top: 15px;
    li {
        &:not(:last-child) {
            margin-right: 5px;
        }
        &:last-child {
            a, span {
                background-color: transparent;
                &:hover {
                    svg path {
                        fill: $c2;
                        stroke: $c2;
                    }
                }
            }
        }
        a,
        span {
            width: 45px;
            height: 45px;
            line-height: 1;
            position: relative;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: #F3F6FC;
            color: #354463;
            font-size: 16px;
            @extend .poppins;
            @extend .medium;
            @extend .radius-5;
            &.current {
                background-color: $c2;
                @extend .white;
            }
            svg path {
                @extend .trans3;
            }
            &:hover,
            &.active {
                color: $white;
                @extend .c2-bg;

                svg path {
                    stroke: #fff;
                    fill: #fff;
                }
            }
        }
        &.nav-btn {
            a.disabled {
                cursor: not-allowed;
                opacity: .5;
            }
        }
    }
}

/* Blockquote */
blockquote {
    max-width: 570px;
    margin: 0 auto 30px;
    @extend .poppins;
    P {
        font-size: 18px;
        line-height: 1.67;
        font-style: italic;
        text-decoration: underline;
        margin-bottom: 15px !important;
    }
    cite {
        font-style: normal;
        font-size: 16px;
        position: relative;
        padding-left: 20px;
        line-height: 1;
        &:after {
            position: absolute;
            content: '';
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 3px;
            width: 10px;
            @extend .text-color-bg;
        }
    }
}

/* Form Response */
.form-response {
    background-color: rgba($c1, .2);
    span {
        display: block;
        margin-top: 20px;
        margin-bottom: 30px;
        padding: 10px 15px;
    }  
}

/* Search Form */
.search-form {
    .theme-input-group {
        border: 1px solid #5551EF;
        @extend .radius-5;
        input {
            background-color: transparent;
            @extend .text-color;
            &::placeholder {
                color: rgba(110, 110, 110, .45) !important;
            }
            &:focus {
                background-color: transparent;
            }
        }

        button {
            background-color: transparent;
            padding: 9px 25px;
        }
    }

    &.style--two {
        .theme-input-group {
            height: 55px;
            border: none;
            background-color: #EFF6FB;
            border-radius: 55px;
            overflow: hidden;
            input {
                height: 50px;
                background-color: #EFF6FB;
                padding-left: 30px;
                margin-bottom: 0;
            }
            button {
                padding: 8px 36px;
                background-color: #00E3F7;
                @extend .radius-50;
                top: 5px;
                height: calc(100% - 10px);
                right: 5px;
            }
        }
    }
}

/* Gutter 50 */
@media only screen and (min-width: 992px) {
	.gutter-50 {
		margin-left: -25px;
		margin-right: -25px;
	}

	.gutter-50>[class*="col-"] {
		padding-left: 25px;
		padding-right: 25px;
	}
}

section {
    @extend .ovx-hidden;
}