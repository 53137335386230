/* ************************
   02.7: Widget
   ********************* */
.widget {
    font-size: 16px;
    &:not(:last-child) {
        margin-bottom: 50px;
    }
    .widget-title {
        h3 {
            margin-bottom: 24px;
            position: relative;
            padding-left: 14px;
            &:after {
                width: 3px;
                height: 17px;
                @extend .c1-bg;
                left: 0;
                top: 3.5px;
                content: "";
                position: absolute;
            }
        }
    }
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    &.widget_newsletter {
        .newsletter-content {
            p {
                margin-bottom: 25px;
            }
        }
        &.style--two {
            .newsletter-content {
                p {
                    font-size: 14px;
                }
                .theme-input-group button {
                    position: static;
                    width: 100%;
                    margin-top: 16px;
                    height: 52px;
                }
                .theme-input-group input {
                    background-color: $white;
                }
            }
        }
    }
    &.widget_contact {
        ul {
            li {
                padding-left: 40px;
                position: relative;
                font-size: 16px;
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
                .icon {
                    position: absolute;
                    left: 0;
                }
                a {
                    @extend .black;
                    &:hover {
                        @extend .c2;
                    }
                }
            }
        }
    }
    &.widget_service {
        .service-list {
            @extend .poppins;
            li {
                line-height: 2;
                display: flex;
                align-items: center;
                a {
                    @extend .text-color;
                    &:hover {
                        @extend .c2;
                        i,
                        svg * {
                            @extend .c2;
                            fill: $c2;
                        }
                    }
                }
                i {
                    font-size: 18px;
                }
                img,
                svg,
                i {
                    margin-right: 10px;
                    @extend .trans3;
                }
                &:not(:last-child) {
                    margin-bottom: 24px;
                }
            }
        }
    }
    &.widget_recent_entries {
        ul {
            li {
                &:not(:last-child) {
                    margin-bottom: 23px;
                }
                .img {
                    margin-right: 30px;
                }
                .posted-by {
                    @extend .c2;
                    @extend .bold;
                    text-transform: uppercase;
                    font-size: 12px;
                    display: block;
                    margin-bottom: 3px;
                }
                .posted-on {
                    display: block;
                    i {
                        margin-right: 5px;
                    }
                    i, a {
                        @extend .text-color;
                        font-size: 14px;
                    }
                }
                .post-title {
                    margin-bottom: 5px;
                    a {
                        line-height: 1.238;
                        @extend .black;
                        @extend .medium;
                        font-size: 21px;
                        &:hover {
                            @extend .c2;
                        }
                    }
                }
            }
        }
    }
    &.widget_search {
        @extend .search-form;
    }
    &.widget_newsletter {
        p {
            margin-bottom: 26px;
        }
    }
    &.widget_tag_cloud {
        .tagcloud {
            margin: 0 -6px -6px 0;
            a {
                display: inline-block;
                padding: 5px 8px;
                background-color: #F9F9F9;
                margin: 0 3px 6px 0;
                @extend .black;
                @extend .medium;
                border-radius: 2px;
                font-size: 13px;
                &:hover {
                    @extend .c1;
                }
            }
        }
    }
    &.widget_nav_menu {
        ul {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: -18px;
            li {
                flex: 0 0 50%;
                padding-left: 16px;
                position: relative;
                &:after {
                    width: 5px;
                    height: 5px;
                    content: "";
                    position: absolute;
                    background-color: #CED3E2;
                    @extend .radius;
                    left: 0;
                    top: 9px;
                }
                a {
                    font-size: 15px;
                    text-transform: capitalize;
                    display: block;
                }
                margin-bottom: 18px;
            }
        }
    }
    &.widget_social_links {
        margin: 0;
        padding: 30px;

        .social-links {
            a {
                background-color: rgba($black, 0.35);
                @extend .white;
        
                &:hover {
                    @extend .white;
                    @extend .c2-bg;
                }
        
            }
        }
    }
    &.widget_social_media {
        .social-media {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: -10px;
            a {
                background-color: #1976D2;
                @extend .text-white;
                padding: 10px;
                flex: 0 0 48.5%;
                margin-bottom: 10px;

                .icon {
                    margin-right: 10px;
                }
                .content {
                    p {
                        &:not(:last-child) {
                            margin-bottom: 5px;
                        }
                        line-height: 1;
                        @extend .bold;
                    }
                }

                &:nth-child(2) {
                    background-color: #1DCAFF;
                }
                &:nth-child(3) {
                    background-color: #3F729B;
                }
                &:nth-child(4) {
                    background-color: #E52D27;
                }
        
            }
        }
    }
    &.widget_categories {
        ul {
            li {
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
                a {
                    position: relative;
                    padding-left: 28px;
                    @extend .text-color;
                    display: block;

                    &:after {
                        position: absolute;
                        background-image: url(../img/icons/angle-right.svg);
                        background-repeat: no-repeat;
                        width: 6px;
                        height: 10px;
                        content: "";
                        left: 0;
                        top: 8px;
                    }

                    &:hover {
                        @extend .c1;
                    }
                }
            }
        }
    }
}

/* Post Sidebar */
.blog-sidebar {
    .widget {
       .widget-title {
           h4 {
               text-transform: uppercase;
               font-size: 21px;
               padding-bottom: 20px;
               margin-bottom: 30px;
               position: relative;
               &:after {
                   height: 3px;
                   width: 30px;
                   background-color: #00E3F7;
                   position: absolute;
                   left: 0;
                   bottom: 0;
                   content: "";
               }
           }
       }
    }
 }