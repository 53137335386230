/* ************************
   03.5: 404
   ********************* */

   .bg-404 {
      background-size: cover;
      display: flex;
      align-items: center;
      padding-top: 100px;
      padding-bottom: 100px;
   }

   .content-404 {
      > img {
         margin-bottom: 67px;
      }
      h1 {
         color: #3C4858;
         margin-bottom: 36px;
      }

      > p {
         margin-bottom: 20px;
         font-size: 18px;
         @include mobileLg {
            br {
               display: none;
            }
         }
      }

      .btn-link {
         svg {
            @extend .trans3;
            right: 0;
         }
         &:hover {
            svg {
               right: 2px;
               position: relative;
               path {
                  fill: $c2;
                  stroke: $c2;
               }
            }
         }
      }
   }
