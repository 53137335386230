/* ************************
   02.5: Section Title
   ********************* */
.section-title {
    margin-bottom: 65px;
    @include tab {
        margin-bottom: 46px;
    }
    h5 {
        @extend .c2;
        @extend .semi-bold;
        margin-bottom: 20px;
    }
    h2 {
        line-height: 1.35;
        margin-top: -10px;
        margin-bottom: 12px;
        letter-spacing: -1.5px;
        @include mobileMd {
            font-size: 32px;
        }
    }
    P {
        font-size: 18px;
        @include mobileMd {
            font-size: 16px;
        }
    }
    @include tab {
        br {
            display: none;
        }
    }
    &.style--two {
        margin-bottom: 35px;

        h2 {
            margin-bottom: 27px;
        }

        p {
            font-size: 16px;
        }
    }
}