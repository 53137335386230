/* ************************
   03.4: Footer
   ********************* */

   .footer {
      background: transparent radial-gradient(closest-side at 50% 50%, #0C5FE7 0%, #16149F 100%) 0% 0% no-repeat padding-box;
      position: relative;
      z-index: 1;
      @extend .text-white;

      .widget {
         background-color: transparent;
         margin-bottom: 60px;

         .widget-title {
            margin-bottom: 40px;
            &:after {
               @extend .white-bg;
            }
         }
         &.widget_contact {
            ul {
               li {
                  .icon {
                     svg path {
                        fill: $white;
                     }
                  }
                  a {
                     @extend .white;
                     &:hover {
                           @extend .c2;
                     }
                  }
               }
            }
        }
        &.widget_recent_entries {
            ul {
               li {
                  .posted-on {
                        i, a {
                           @extend .white;
                        }
                  }
                  .post-title {
                        a {
                           @extend .white;
                           &:hover {
                              @extend .white;
                           }
                        }
                  }
               }
            }
         }
      }
   }

   .footer-bg-shape {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: -1;
   }

   .footer-top {
      padding: 80px 0 14px;
   }

   .footer-bottom-content {
      position: relative;
      padding: 35px 0;
      > div {
         color: #CED3E2;
      }
      a {
         color: #CED3E2;
         &:hover {
            color: $c2;
         }
      }
      &:after {
         width: 100%;
         height: 1px;
         left: 0;
         top: 0;
         @extend .white-bg;
         position: absolute;
         content: "";
         opacity: 0.15;
      }
   }