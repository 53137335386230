/* ************************
   03.1: Header
   ********************* */


.header {
   z-index: 1029;
}

// Header Main
.header-main {
   margin-top: 25px;
   padding: 15px 0;
   min-height: 80px;
   display: flex;
   align-items: center;

   &.sticky {
      @extend .white-bg;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
      margin-top: 0;
      .nav > li > a {
         @extend .black;
      }

      .search-toggle-btn {
         svg path {
            fill: $black;
         }
      }
      .offcanvas-trigger span {
         @extend .black-bg;
      }

      .flag-dropdown {
         .dropdown-btn svg g path {
            stroke: $black;
         }
      }

      .logo {
         .sticky-logo {
            display: block;
         }
         .main-logo {
            display: none;
         }
      }
      #menu-button {
         span {
            background-color: $black;
            &:before,
            &:after {
               background-color: $black;
            }
         }
      }
      .menu-mark {
         @extend .c1-bg;
         @include tab {
            display: none;
         }
   
         &:before,
         &:after {
            width: 5px;
            height: 5px;
            @extend .c1-bg;
            border-radius: 50%;
            position: absolute;
            display: block;
            content: "";
         }
      }
      .offcanvas-trigger {
         @extend .c2-bg;
      }
   }

   .flag-dropdown {
      .dropdown-btn {
         min-width: 54px;
      }
   }

   .logo {
      .sticky-logo {
         display: none;
      }
   }

   .menu-mark {
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      visibility: hidden;
      width: 5px;
      height: 5px;
      background-color: #fff;
      border-radius: 50%;
      position: absolute;
      display: block;
      transition: 0.3s ease-in 0.1s;
      @include tab {
         display: none;
      }

      &:before,
      &:after {
         width: 5px;
         height: 5px;
         background-color: #fff;
         border-radius: 50%;
         position: absolute;
         display: block;
         content: "";
      }
      &:before {
         left: -10px;
         transition: 0.3s ease-in;
      }
      &:after {
         left: 10px;
         transition: 0.3s ease-in 0.2s;
      }
   }

   @include tab {
      .submenu-button {
         position: absolute;
         right: 0;
         top: 10px;
         height: 30px;
         width: 30px;
         color: inherit;
         font-size: 14px;
         z-index: 9999;
         transition: 0.2s ease-in-out;
         &:after {
            font-family: 'FontAwesome';
            content: "";
            line-height: 30px;
            justify-content: center;
            display: flex;
            align-items: center;
         }
         &.submenu-opened:after {
            content: '\f0d8';
            color: #5551ef;
         }
      }
   }

   ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      &.nav {
         @include tab {
            flex-direction: column;
            background-color: #fff;
            box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.16);
            width: calc(100% - 30px);
            position: absolute;
            left: 15px;
            top: 110%;
            z-index: 9;
            > li {
               width: 100%;
            }
         }
         > li > a {
            line-height: 50px;
            color: $white;
            position: relative;
            @include tab {
               color: $black !important;
               display: block;
            }
         }
         li:hover > ul {
            top: 100%;
         }

         ul {
            position: absolute;
            left: 0;
            top: -2000px;
            opacity: 0;
            visibility: hidden;
            background-color: #ffffff;
            width: 200px;
            transform: translate3d(0, -5px, 0);
            transition: transform 0.3s ease-out;
            z-index: 9;
            border-radius: 0 0 5px 5px;
            padding: 5px 0;
            box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.16);
            @include tab {
               position: static;
               box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.16);
               width: 100%;
   
               li {
                  margin-left: 20px;
   
                  a {
                     &:before,
                     &:after {
                        display: none;
                     }
                  }
               }
            }
            li {
               a {
                  padding: 8px 20px;
                  @extend .medium;
                  display: block;
   
                  &:before,
                  &:after {
                     position: absolute;
                     left: 0;
                     @extend .c1-bg;
                     content: "";
                     transition: all 0.3s ease-out;
                  }
                  &:before {
                     top: 50%;
                     transform: translateY(-50%);
                     height: 18px;
                     width: 2px;
                     content: "";
                     opacity: 0;
                     visibility: hidden;
                  }
   
                  &:hover,
                  &.current-menu-children {
                     @extend .c1;
                     &:before {
                        opacity: 1;
                        visibility: visible;
                     }
                  }
               }
   
               &:not(:last-child) {
                  a {
                     &:after {
                        bottom: 0;
                        height: 1px;
                        width: 100%;
                        opacity: 0.1;
                     }
                  }
               }

               

               &:hover > ul {
                  top: 0;
               }
            }
   
            ul {
               left: 100%;
            }
   
            &.open {
               visibility: visible;
               opacity: 1;
            }
         }
      }
      li {
         position: relative;
         a {
            color: $black;
            @extend .medium;
            padding: 0 20px;
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: 1px;

            &.current-menu-parent,
            &:hover {
               .menu-mark {
                  opacity: 1;
                  visibility: visible;
               }
            }

            &:hover {
               @extend .c1;
            }
         }

         &:hover > ul {
            opacity: 1;
            visibility: visible;
            top: 100%;
            transform: translate3d(0, 0, 0);
         }
      }
   }
   &.bg-white {
      margin: 0;
      ul.nav > li > a {
         @extend .black;
      }

      .flag-dropdown .dropdown-btn,
      .search-toggle-btn {
         svg path, i {
            color: $black;
            stroke: $black;
            fill: $black;
         }
      }

      .offcanvas-trigger span {
         @extend .black-bg;
      }

      #menu-button {
         span {
            background-color: $black;
            &:before,
            &:after {
               background-color: $black;
            }
         }
      }
   }
}


// Offcanvas Triggar
.offcanvas-trigger {
   width: 40px;
   min-width: 40px;
   height: 40px;
   @extend .radius;
   background-color: rgba(0, 0, 0, .2);
   display: inline-flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
}


// Flag Dropdown
.flag-dropdown {
   .dropdown-btn {
      img, svg {
         display: inline-block;
         @extend .radius;
         &:not(:last-child) {
            margin-right: 8px;
         }
      }
   }

   .dropdown-menu {
      min-width: auto;
      padding: 10px 0;
      li {
         a {
            padding: 10px;
         }
      }
   }
}

// Full Page Search
.full-page-search {
   position: fixed;
   height: 100%;
   width: 100%;
   background-color: #fff;
   z-index: 1200;
   top: 0;
   left: 0;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   align-items: center;
   justify-content: center;
   opacity: 0;
   visibility: hidden;
   transition: opacity 0.3s linear;

   &.show {
      opacity: 1;
      visibility: visible;
   }

   .search-close-btn {
      position: absolute;
      right: 15%;
      top: 15%;
      font-size: 20px;
   }

   .input-wrapper {
      position: relative;
      border-bottom: 1px solid #ddd;
      margin-bottom: 30px;

      input {
         width: 100%;
         font-size: 15px;
         background-color: transparent;
         border: 0;
         padding-left: 35px;
         padding-bottom: 6px;
      }
      .input-icon {
         position: absolute;
         left: 10px;
         top: 0px;

         i {
            @extend .text-color;
         }
      }
      + .btn {
         background-color: $c2;
      }
   }
}

//Offcanvas menu
.offcanvas-wrapper {
   overflow-x: hidden;
   overflow-y: auto;
   max-width: 400px;
   width: 100%;
   right: 0;
   left: auto;
   @extend .trans3;
   @extend .white-bg;
   transform: translateX(100%);
   z-index: 1031;

   &.show {
      transform: translateX(0);
   }

   .offcanvas-close {
      cursor: pointer;
      z-index: 999;
      top: 30px;
      right: 30px;
   }
   .widget:not(:last-child) {
      padding-bottom: 30px;
      border-bottom: 1px solid rgba(57, 62, 92, 0.09);
   }
   .widget {
      .widget-title {
         margin-bottom: 15px;
         position: relative;
         padding-left: 10px;
         font-size: 24px;
         &:before {
            position: absolute;
            left: 0;
            top: 50%;
            width: 3px;
            height: 15px;
            @extend .c1-bg;
            content: "";
            transform: translateY(-50%);
         }
      }
   }

   .offcanvas-content {
      padding: 40px;
   }
}

.offcanvas-overlay {
   @extend .black-bg;
   @extend .trans3;
   opacity: 0;
   visibility: hidden;
   &.show {
      opacity: .9;
      visibility: visible;
   }
}

/* Menu Button */
#menu-button {
   display: none;
   height: 25px;
   width: 25px;
   align-items: center;
   justify-content: center;
   position: relative;
   top: -2px;
   margin-right: 10px;
   @include tab {
      display: inline-flex;
   }
   span {
      position: relative;
      display: block;
      width: 25px;
      height: 2px;
      background-color: #ffffff;
      &:before,
      &:after {
         position: absolute;
         content: "";
         display: block;
         width: 25px;
         height: 2px;
         background-color: #ffffff;
         transition: transform .3s ease;
      }
      &:before {
         top: -7px;
      }
      &:after {
         top: 7px;
      }
   }

   &.menu-opened {
      span {
         background-color: transparent !important;
         &:before {
            transform: translateY(7px) rotate(45deg);
         }
         &:after {
            transform: translateY(-7px) rotate(-45deg);
         }
      }
   }
}