/* ************************
   04.7: About
   ********************* */

    section.about {
            background-size: auto;
            background-position: 0 50px;
    }

    .about-img {
        @include tab {
            margin-bottom: 60px;
        }
    }

    .single-feature {
        padding: 50px 30px 46px;
        border: 1px solid #F0F0F0;
        background-color: $white;
        background-size: auto;
        background-position: right bottom;
        position: relative;
        @extend .trans3;
        margin-bottom: 30px;

        @include mobileLg {
            padding: 40px 20px;
        }

        &.active {
            @extend .box-shadow;
            border-bottom-color: $c2;

            .btn-link {
                text-decoration: none;
                @extend .c2;
                svg {
                    circle {
                        fill: $c2;
                    }
                }
            }
        }

        h4 {
            font-size: 24px;
            position: relative;
            z-index: 1;
            line-height: 1.166;
            letter-spacing: -1px;
            margin-bottom: 20px;

            &:after {
                position: absolute;
                left: 0;
                top: -30px;
                content: "01";
                font-size: 80px;
                @extend .poppins;
                @extend .semi-bold;
                opacity: 0.07;
                z-index: -1;
            }
        }
        p {
            margin-bottom: 25px;
        }

        &.feature-two h4:after {
            content: "02";
        }
        &.feature-three h4:after {
            content: "03";
        }
        &.feature-four h4:after {
            content: "04";
        }
    }