/* ************************
   04.3: Call To Action
   ********************* */

   .cta,
   .newsletter {
       padding: 80px 0 90px;
   }

    .cta-content,
    .subscribe-newsletter {
        h3 {
            line-height: 1.333;
            margin-bottom: 35px;
            @extend .white;

            @include mobileMd {
                font-size: 28px;
            }
        }
        p {
            @extend .white;
            opacity: 0.4;
        }
    }