/* ************************
   04.2: Use Govpn
   ********************* */

    .use-govpn {
        background-size: auto;
        background-position: left top;
    }

    .tab-wrap {
        ul.nav {
            margin-bottom: 60px;
        }
        .nav-item {
            width: 24.25%;
            @include tab {
                width: 48.5%;
            }
            @include mobileLg {
                width: 47%;
            }
            @include mobileSm {
                width: 100%;
            }
            margin-bottom: 10px;
            &:not(:last-child) {
                margin-right: 1%;
                @include mobileLg {
                    margin-right: 2%;
                }
                @include mobileSm {
                    margin-right: 0;
                }
            }
            a {
                @extend .box-shadow;
                @extend .white-bg;
                padding: 30px 15px;
                border-radius: 3px;
                display: block;
                text-align: center;
                position: relative;
                &:after {
                    width: 70%;
                    height: 2px;
                    @extend .c2-bg;
                    left: 0;
                    bottom: 0;
                    position: absolute;
                    content: "";
                    opacity: 0;
                }

                &.active {
                    &:after {
                        opacity: 1;
                        width: 100%;
                        @extend .trans3;
                    }
                }
                .img {
                    margin-bottom: 20px;
                }
            }
        }

        .tab-img {
            @include tab {
                margin-bottom: 50px;
            }
        }
    }