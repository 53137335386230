/* ************************
   04.4: Price
   ********************* */

    .pricing {
        overflow: hidden;
    }

    .price-box {
        @extend .box-shadow2;
        @extend .white-bg;
        @extend .radius-5;
        padding: 44px 15px 0;
        margin-bottom: 50px;
        text-align: center;

        .price-head {
            margin-bottom: 30px;
            
            p {
                text-transform: uppercase;
                @extend .c2;
                font-size: 13px;
                margin-bottom: 12px;
            }

            .plan {
                @extend .radius-50;
                background-color: #F7F8F9;
                padding: 14px 30px;
                max-width: 200px;
                margin: 0 auto;
                position: relative;
                z-index: 1;
                overflow: hidden;
                @extend .trans3;

                h5 {
                    @extend .trans3;
                }

                &:after {
                    width: 100%;
                    height: 100%;
                    @extend .c1-bg;
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: "";
                    z-index: -1;
                    border-radius: 50%;
                    transform: translateY(-100%);
                    transition: transform .15s ease-in-out, opacity .15s ease-in-out .15s, border-radius .15s ease-in-out .15s;
                    opacity: .3;
                    
                }
            }
        }

        .price {
            @extend .poppins;
            @extend .black;
            line-height: 1;
            padding-bottom: 7px;
            @extend .bold;
            @extend .trans3;
            font-size: 14px;
            .currency {
                vertical-align: top;
                font-size: 22px;
            }
            .value {
                    @extend .bold;
                    font-size: 42px;
            }
        }

        .price-body {
            p {
                margin-bottom: 26px;
            }
            ul {
                @extend .list-unstyled;
                margin-bottom: 14px;

                li {
                    margin-bottom: 4px;
                    @extend .black;

                    del {
                        @extend .c2;
                    }
                }
            }
            .btn {
                transform: translateY(50%);
            }
        }

        &:hover,
        &.active {
            .price-head {
                .plan {
                    background-color: rgba(22, 20, 159, .3);
                    h5 {
                        @extend .white;
                    }
                    &:after {
                        transform: translateY(0);
                        border-radius: 50px;
                        opacity: 1;
                    }
                }
            }
        }
    }

    .payment-info {
        p {
            margin-bottom: 24px;
            @extend .black;
            font-size: 24px;
        }
    }