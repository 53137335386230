/* ************************
   02.6: Page Title
   ********************* */

.page-title-wrap {
    min-height: 500px;
    background-size: cover;
    padding-top: 180px;
    padding-bottom: 150px;
    display: flex;
    align-items: center;
    @extend .c1-bg;
    @include mobileSm {
        min-height: 440px;
    }
}

.page-title {
    h1 {
        margin-top: -10px;
        font-size: 60px;
        line-height: 1.375;
    }
    ul {
        margin-bottom: -8px;
        li {
            font-size: 16px;
            @extend .poppins;
            @extend .semi-bold;
            position: relative;
            z-index: 1;
            &:not(:last-child) {
                padding-right: 20px;
                margin-right: 10px;
                &:after {
                    right: 0;
                    position: absolute;
                    background-image: url(../img/icons/double-arrow.svg);
                    background-repeat: no-repeat;
                    content: '';
                    top: 50%;
                    transform: translateY(-50%);
                    width: 11px;
                    height: 11px;
                }
            }
        }
    }
}